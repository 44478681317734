import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const BottomMenu = () => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				menu(id: "TWVudToxNw==") {
					menuItems {
						nodes {
							url
							label
						}
					}
				}
			}
		}
	`)
	const { nodes } = WP.menu.menuItems
	return (
		<div className='footer-menu'>
			{nodes.map((el, k) => {
				return (
					<li className='footer-menu-item' key={k}>
						<Link to={`${el.url}/`}>{el.label}</Link>
					</li>
				)
			})}
		</div>
	)
}

export default BottomMenu
