import React, { useRef } from 'react'
import { Modal , Stepper} from '@common'
import { connect } from 'react-redux'
import CustomerForm from './CustomerForm'
import LoginForm from './LoginForm'
import { customerActions, cartActions } from '@stores'
import { createCustomer, getCustomers, loginCustomer, updateCustomer } from './logic'

import './auth.scss'

const localStorageKey = 'loginDetails'

class Auth extends React.Component {
	state = {
		isLogged: 'false',
		message: '',
		imBusy: false,
	}
	modal = React.createRef()
	stepper = React.createRef({ setIndex: () => { } })

	componentDidMount(){
		if(localStorage.getItem('isLoggedIn')==='true'){
			this.setState({
				isLogged:'true'
			})
		} 
	}

	render() {
		const {
			store,
			changeValue,
			className,
			outside=false,
		} = this.props

		let loginCredentials = ''
		if (typeof window !== 'undefined') {
			if(localStorage.getItem('loginDetails')){
				loginCredentials = JSON.parse(localStorage.getItem(localStorageKey)) || []
			}
		}
		
		return (
			<div className='auth'>
				{this.props.outside === true ? (
								<a className="button"
								onClick={() => {
									this.modal.current.toggle()
									this.setState({
										message:''
									})
								}}>TWOJE KONTO</a>
				):(
					<a
						className='app-menu-btn'
						onClick={() => {
							this.modal.current.toggle()
							this.setState({
								message:''
							})
						}}
					>
						<i className='icofont-user' />
					</a>
				)}
					
				<Modal title={''} ref={this.modal}>
					<div className='auth-modal'>
						{this.state.isLogged != 'true' ? (
							<div>
								<Stepper ref={this.stepper} withScroll={true}>
									<div
										className='col-12'
										title={'Zaloguj się'}
									>
										<LoginForm store={store} changeValue={changeValue} message={this.state.message} imBusy={this.state.imBusy}
											onLogin={() => {
												this.setState({
													imBusy: true
												})
                                                loginCustomer( store.customer.email, store.customer.password)
													.then((data) => {
														localStorage.setItem(localStorageKey, JSON.stringify(data))
														localStorage.setItem('isLoggedIn', 'true')
														this.setState({
															isLogged: 'true',
															imBusy: false,
                                                            message:'Zostałes pomyślnie zalogowany'
														})
													})
													.catch(({ data }) => {
                                                        this.setState({
															imBusy: false,
                                                            message:'Nieprawidłowe dane. Spróbuj ponownie'
														})
													})
											}}
										/>
									</div>
									<div
										className='col-12'
										title={'Załóż nowe konto'}
									>
										<CustomerForm store={store} changeValue={changeValue} message={this.state.message} imBusy={this.state.imBusy}
											onCreateCustomer={() => {
												this.setState({
													imBusy: true
												})
												createCustomer(store.customer)
													.then((data) => {
														localStorage.setItem(localStorageKey, JSON.stringify(data))
														localStorage.setItem('isLoggedIn', 'true')
														this.setState({
															isLogged: 'true',
															imBusy: false,
															message: 'Konto założone pomyślnie.'
														})
													})
													.catch(({ data }) => {
														this.setState({
															imBusy: false,
                                                            message:'Nieprawidłowe dane. Spróbuj ponownie'
														})
													})
											}
											}
										/>
										
									</div>
								</Stepper>
							</div>
						) : (
                            <div>
                                <CustomerForm store={store} changeValue={changeValue} update={true} message={this.state.message}
									onCustomerUpdate={() => {
										this.setState({
											imBusy: true
										})
										updateCustomer(store.customer, loginCredentials.id)
											.then((data) => {
												this.setState({
													imBusy: false,
													message: 'Twoje dane zostały pomyślnie zaktualizowane.'
												})
											})
											.catch(({ data }) => {
												this.setState({
													imBusy: false,
													message: 'Ups, coś poszło nie tak. Sprawdź dane i spróbuj ponownie.'
												})
											})
									}
									}
									onLogOff={() => {
										this.setState({
											isLogged: 'false',
											message: 'Pomyslnie wylogowano'
										})
										localStorage.removeItem('isLoggedIn')
									}}
								/>
                            </div>
							)}
					</div>
					{/* desktop */}
					<div className='auth-footer'>
					</div>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { store: state.Customer }
}

const mapActionToDispatch = { ...customerActions }

export default connect(mapStateToProps, mapActionToDispatch)(Auth)
