import React from 'react'

const onKeyDown = (ev, onChange, quantity)=>{
	if(ev.key === 'ArrowUp'){
		onChange(quantity + 1)
	}
	if(ev.key === 'ArrowDown'){
		onChange(quantity - 1)
	}
}

const QuantityChanger = ({ onChange, quantity }) => {
	const qty = Number(quantity)
	return (
		<div className='quantity-changer'>
			<a
				className='quantity-control'
				onClick={() => {
					onChange(qty - 1)
				}}
			>
				<i className='icofont-minus' />
			</a>
			<input
				className='quantity-input'
				value={qty}
				onChange={(ev) => {
					onChange(ev.target.value)
				}}
				onKeyDown={(ev)=>{
					onKeyDown(ev,onChange,qty)
				}}
				type='text'
				min='1'
				max='999999'
				step='1'
			/>
			<a
				className='quantity-control'
				onClick={() => {
					onChange(qty + 1)
				}}
			>
				<i className='icofont-plus' />
			</a>
		</div>
	)
}

export default QuantityChanger
