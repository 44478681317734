import React, { useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import memoize from 'fast-memoize'
import { WindowCtx } from '../../../reduxWrapper'
import { getSortedSrcSet } from '@common/Image'
import './parallax.scss'

const img =
	'https://grono.api.localhost-group.com/wp-content/uploads/2020/03/attention_bg_image-1200x750.jpg'
	
const onScroll = ({ height, width }, { x, y }, ref) => {
	if (ref.current) {
		const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = ref.current
		if (y > offsetTop + offsetHeight) {
			return '-50%'
		} else {
			const diff = 0.2 * (offsetTop - y) / (offsetTop + offsetHeight) * 100
			return -50 + diff + '%'
		}
	} else {
		return -'50%'
	}
}


const getProperImgSize = memoize((image, { width }) => {
	let fittedImg = null
	const sortedSrcSet = getSortedSrcSet(image.srcSet)

	for (const [properImgSize, _, sizes] of sortedSrcSet) {
		if (sizes && sizes[0] >= width) {
			fittedImg = properImgSize
			break
		}
	}

	if(fittedImg === null){
		fittedImg = sortedSrcSet[sortedSrcSet.length - 1][0]
	}

	return fittedImg || img
})
 
const ParallaxInner = ({ children, value, image, height, bordered }) => {
	const ref = useRef(null)
	const [imBusy, setBusy] = useState(true)

	const heightObj = height ? {height: height,
		minHeight: height} : {
		}

	return (
		<div ref={ref} className={['parallax',bordered?'bordered':''].join(' ')}
		style={{
			...heightObj,
		}}
		>
			<img src={getProperImgSize(image, value.window)} 
			style={{
				transform: `translate(-50%, ${onScroll(value.window, value.scroll, ref)})`,
				}}
				className="parallax-img" 
				onLoad={(ev)=>{ setBusy(false) }}
			/>
			<div className='parallax-inner'>{children}</div>
		</div>
	)
}

const Parallax = ({ image, children, height, bordered }) => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "attention_bg_image") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)
	return (
		<WindowCtx.Consumer>
			{(value) => (
				<ParallaxInner image={image || WP.mediaItemBy} 
				bordered={bordered}
				value={value} height={height}>
					{children}
				</ParallaxInner>
			)}
		</WindowCtx.Consumer>
	)
}

export default Parallax

 