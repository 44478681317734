import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { TopMenu, MobileMenu, ResizedImage as Image } from '../'
import './header.scss'


const logs = [
	{ id: 0, url: 'uploads/2020/05/winny-sklad-logo.png' },
	{ id: 1, url: 'uploads/2020/03/grono_logo.png' },
  ]

const Logos = () => {
	const [index, set] = useState(0)
	const transitions = useTransition(logs[index], item => item.id, {
	  from: { opacity: 0 },
	  enter: { opacity: 1 },
	  leave: { opacity: 0 },
	  config: config.molasses,
	})
	useEffect(() => void setInterval(() => set(state => (state + 1) % 2), 5000), [])
	return transitions.map(({ item, props, key }) => (
	  <animated.div
		key={key}
		class="logo"
		style={{ ...props, backgroundImage: `url(https://grono.api.localhost-group.com/wp-content/${item.url})` }}
	  />
	))
  }

const Logo = () => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				mediaItemBy(slug: "grono_logo") {
					sourceUrl
					srcSet
					altText
				}
			}
		}
	`)
	return <Image {...WP.mediaItemBy} size={'300w'} />
}


const Header = () => {
	return (
		<div className='app-top-menu'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-3 col-6'>
						<div className='logo'>
							<Link to={'/'}>
								<Logos />
							</Link>
						</div>
					</div>

					<div className='col-6 d-md-none'>
						<MobileMenu />
					</div>

					<div className='col-md-9 d-none d-md-block'>
						<TopMenu />
					</div>
				</div>
			</div>
		</div>
	)
}
export {Logo}
export default Header
