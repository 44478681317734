import React, { useState } from 'react'
import Slider from 'react-slick'

const settings = {
	infinite: true,
	arrows: true,
	slidesToShow: 4,
	dotsClass: 'slick-dots slick-thumb',
	slidesToScroll: 1,
	autoplay: false,
	speed: 400,
	autoplaySpeed: 5000,
	className: 'slick-slide-inner',
	cssEase: 'ease-in-out',
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
			},
		},
	],
}

const renderDefaultSlideItem = ({element, idx}) => {
	return <div>{idx}</div>
}

const AppSlider = (props) => {
	const {
		SliderItem = renderDefaultSlideItem,
		renderSliderDescription,
		items=[],
		onSlideChange=()=>false,
		onSlideClick = ()=>false,
		customSettings = {}, title=null
	} = props
	return (
		<React.Fragment>
			{title && <h2 className="text-center">{title}</h2>}
			<Slider {...settings}
				{...customSettings}
				slickNext={onSlideChange}
				slickPrev={onSlideChange}
			>
				{items.map((element, idx) => {
					return <SliderItem {...element} onClick={()=>{onSlideClick(element)}} key={idx}/>
				})}
			</Slider>
		</React.Fragment>
	)
}

export default AppSlider
