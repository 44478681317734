import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import {Cart,Search, Auth} from '..'

const TopMenu = () => {
	const { WP } = useStaticQuery(graphql`
		query {
			WP {
				menu(id: "TWVudToxNg==") {
					menuItems {
						nodes {
							url
							label
						}
					}
				}
			}
		}
	`)
	const { nodes } = WP.menu.menuItems

	return (
        <ul className='app-menu'>
            {nodes.map((el, k) => {
                return (
                    <li key={k}>
                        <Link to={`${el.url}`} activeClassName="active"
						onClick={() => {
                            trackCustomEvent({
								category: "Menu - ".concat(el.label),
                                action: "Menu item selection",
                                label: "Menu selection",
                            })
                            }}>{el.label}</Link>
                    </li>
                )
            })}
            <li>
                <Cart />
            </li>
            <li>
                <Search />
            </li>
            <li>
                <Auth />
            </li>
        </ul>
	)
}

export default TopMenu