import React, { useState } from 'react'
import { TextInput } from '@common'
import { Link, navigate } from 'gatsby'

const CustomerForm = ({ store, changeValue, onCreateCustomer, update = false, onCustomerUpdate, onLogOff, message, imBusy }) => {
	const [checked, setChecked] = useState(false)
	const id = localStorage.getItem('loginDetails.id')

	let empty = false
	if (store.customer.first_name == '' ||
		store.customer.last_name == '' ||
		store.customer.phone == '' ||
		store.customer.email == '') {
		empty = true
	}
	let disabled = false
	if (empty === true || checked === false) {
		disabled = true
	}
	return (
		<div className='auth-form'>
			<form
				className='customer-form'
				onSubmit={(ev) => {
					ev.preventDefault()
				}}
			>
				{update === true ?
					(
						<div className='row justify-content-center'>
							<div className='col-12 col-md-6'>
								<h5>Witaj, w panelu użytkownika {store.customer.email}! </h5>
							</div>
						</div>
					) : (
						null
					)
				}
				
				<h5>Dane osobowe</h5>
				<div className="row justify-content-center">
					<div className='col-12 col-md-6'>
						<TextInput
							placeholder='Imię'
							name='firstName'
							value={store.customer.first_name}
							validate={'text'}
							onChange={(ev) => {
								changeValue({
									first_name: ev.target.value,
								})
							}}
						/>
						<TextInput
							placeholder='Nazwisko'
							name='lastName'
							value={store.customer.last_name}
							onChange={(ev) => {
								changeValue({
									last_name: ev.target.value,
								})
							}}
						/>
						<TextInput
							placeholder='Telefon'
							name='phoneNo'
							validate={'number'}
							value={store.customer.phone}
							onChange={(ev) => {
								changeValue({
									phone: ev.target.value,
								})
							}}
						/>
						<TextInput
							type={'email'}
							name='email'
							validate={'email'}
							placeholder='Adres email'
							value={store.customer.email}
							onChange={(ev) => {
								changeValue({
									email: ev.target.value,
									username: ev.target.value
								})
							}}
						/>
						<TextInput
							placeholder='Haslo'
							type="password"
							name='password'
							value={store.customer.password}
							validate={'text'}
							onChange={(ev) => {
								changeValue({
									password: ev.target.value
								})
							}}
						/>
					</div>
				</div>
				{update === false ? (
					<div className="row justify-content-center">
						<div className='col-12 col-md-12'>
							<div>
								<input
									type="checkbox"
									placeholder="over18"
									checked={checked}
									onChange={() => {
										setChecked(!checked)
									}}
								/>
								<label>
									Jestem osobą pełnoletnią.
				</label>
							</div>
						</div>

					</div>
				) : (
						null
					)}
				
				<div className="row justify-content-center">
					{update === true ?
						(
							<div className="row justify-content-center">
								<div className='col-12 col-md-12'>
									<button className='app-button' onClick={onCustomerUpdate}>ZAKTUALIZUJ DANE</button>
								</div>
								<div className='col-12 col-md-12'>
									<button className='app-button' onClick={onLogOff}>WYLOGUJ SIE</button>
								</div>
							</div>
						) : (
							<button disabled={disabled} className='app-button' onClick={onCreateCustomer}>STWÓRZ KONTO</button>
						)
					}
				</div>
				{imBusy === true ? (
					 <div class="spinner-border"></div>
				):(null)}
				<div className="row justify-content-center">
					<h5>{message}</h5>
				</div>
			</form>
		</div>
	)
}

export default CustomerForm