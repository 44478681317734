import React from 'react'
import {truncate} from 'lodash'
import {Link} from 'gatsby'
import {ResizedImage as Image} from '@common'

const BlogItem = ({ featuredImage, slug, title, content, onClick, className=[] }) => {
	return (
		<div className={['blog-item', ...className].join(' ')}>
			<Link to={`/blog/${slug}/`}
			onClick={() => {
                // onClick({slug, title})
			  }}>
				<div className="blog-item-image">
					<Image {...featuredImage} size={'600w'}/>
                    <div className="blog-item-inner">
                    <div className="blog-item-title">
                        <h5 dangerouslySetInnerHTML={{__html:truncate(title, {length:70})}}/>
                        </div>
						<button className="blog-item-button">Zobacz więcej</button>
					</div>
				</div>
			</Link>
		</div>
	)
}


export default BlogItem