import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Modal, TextInput } from '../'
import { searchActions } from '@stores'
import './search.scss'


const urlRE = new RegExp('.(gif|jpe?g|tiff|png|webp|bmp)', 'i')

const replaceUrlWithSize = (url, size = 150) => {
	const [ext] = urlRE.exec(url)
	return url.replace(ext, `-${size}x${size}${ext}`)
}

const SearchListElem = ({ title, slug, type, onClick, image }) => {
	if (type === 'product') {
		return (
			<li className='col-6 col-md-4 product'>
				<Link to={`/products/${slug}/`} onClick={onClick}>
					<img src={replaceUrlWithSize(image)} />
				</Link>
				<h6>{title}</h6>
			</li>
		)
	}
	if (type === 'post') {
		return (
			<li className='col-6 col-md-4 post'>
				<Link to={`/blog/${slug}/`} onClick={onClick}>
					<h6>{title}</h6>
				</Link>
			</li>
		)
	}
	return (
		<li className='col-6 col-md-4 page'>
			<Link to={`/${slug}/`} onClick={onClick}>
				<h6>{title}</h6>
			</Link>
		</li>
	)
}

class Search extends Component {
	modal = React.createRef()

	render() {
		const { phrase, all, filtered, setPhrase, imBusy } = this.props
		const placeholder =
			all.length === 0 ? '...' : <i className='icofont-search' />

		return (
			<div className='app-search'>
				<a
					className='app-menu-btn'
					disabled={imBusy}
					onClick={() => {
						this.modal.current.toggle()
					}}
				>
					{placeholder}
				</a>
				<Modal ref={this.modal} title={'Znajdź to co Cię interesuje'}>
					<div className='app-search-form'>
						<form
							onSubmit={(ev) => {
								ev.preventDefault()
							}}
						>
							<TextInput
								autofocus={true}
								value={phrase}
								onChange={(ev) => {
									setPhrase(ev.target.value)
								}}
								placeholder={'Wyszukaj na stronie'}
							/>
							<div className='input-icon'>
								<i className='icofont-search' />
							</div>
						</form>
						<ul className='search-list row'>
							{filtered.length === 0 && (
								<div className='no-elements'>
									Brak szukanych elementów
								</div>
							)}
							{filtered.map((el, k) => {
								return (
									<SearchListElem
										{...el}
										key={k}
										onClick={() => {
											setPhrase('')
										}}
									/>
								)
							})}
						</ul>
					</div>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.Search,
})
const mapActionToDispatch = { ...searchActions }

export default connect(mapStateToProps, mapActionToDispatch)(Search)
