import React from 'react'
import { ContactForm, BottomMenu, ResizedImage as Image } from '../'
import {Logo } from '../Header'
import { useStaticQuery, graphql, Link } from 'gatsby'
import './footer.scss'


const ContactBlock = () => {
	return (
		<div className='footer-contact-block'>
			<div className='row'>
				<div className='col-sm-12 col-4'>
					<div className='logo'>
						<Link to={'/'}>
							<Logo/>
						</Link>
					</div>
				</div>

				<div className='col-sm-12 col-8' id={'contact'}>
					<h6 className='footer-address'>Jasna 7, Lublin</h6>
					<h6 className='footer-address'>20-077</h6>
					<h6 className='footer-email'>
						<a href={`mailto:biuro@gronolublin.pl`}>biuro@gronolublin.pl</a>
					</h6>
					<h6 className='footer-phone'>
						<a href={`tel:+48695462727`}>+48 695 462 727</a>
					</h6>
					<div className="social-links">
						<a href="https://www.facebook.com/gronosklepibar/" target="_blank"><i className="icofont-facebook"></i></a>
						<a href="https://www.instagram.com/gronosklepibar/" target="_blank"><i className="icofont-instagram"></i></a>
						<a href="https://twitter.com/GronoWineBar" target="_blank"><i className="icofont-twitter"></i></a>

					</div>
				</div>
			</div>
		</div>
	)
}

export const Footer = () => {
	return (
		<React.Fragment>
			<footer className='footer'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-6 col-md-4'>
							<ContactBlock />
						</div>
						<div className='col-xs-12 col-sm-6 col-md-4'>
							<BottomMenu />
						</div>
						{/*
					<div className='col-xs-12 col-sm-12 col-md-4'>
						<ContactForm />
					</div>
					*/}
					</div>
				</div>
			</footer>
			<div className='footer-author'>
				<div className='container'>
					<div className='row'>
						
						<div className='col'>
							<a href='https://academy.localhost-group.com/'>
								Wykonanie: Localhost Group
							</a>
						</div>
						<div className='col  text-right'>
							<a href='https://biuroreklama.pl/'>
								DESIGN: Localhost Group
							</a>
						</div>
					</div>
				</div>
			</div>

			
		</React.Fragment>
	)
}
export default Footer
