import React from 'react'
import {truncate} from 'lodash'
import {Link} from 'gatsby'
import {ResizedImage as Image} from '@common'

const Prices = ({regularPrice, salePrice})=>{

    if(salePrice){
        return (<h5 className="product-item-price">
            <span className="strike" dangerouslySetInnerHTML={{__html:regularPrice.replace('zł','PLN')}}></span>
            <small  dangerouslySetInnerHTML={{__html:salePrice.replace('zł','PLN')}}></small>
        </h5>)
    }
    else{
        return (<h5 className="product-item-price" ><span dangerouslySetInnerHTML={{__html:regularPrice.replace('zł','PLN')}}/></h5>)
    }
}

const ProductItem = (props) => {
    const { image, slug, name, content, onClick, regularPrice, salePrice, className=[] } = props
	return (
		<div className={['product-item', ...className].join(' ')}>
			<Link to={`/products/${slug}/`}
			onClick={() => {
                // onClick({slug, name})
			  }}>
				<div className="product-item-image">
					<Image {...image} size={'300w'}/>
                    <div className="product-item-inner">
						<button className="product-item-button">Zobacz</button>
                    </div>
                </div>
                <div className="product-item-footer">
                <div className="product-item-title">
                    <h5 dangerouslySetInnerHTML={{__html:name}}/>
                </div> 
                {regularPrice && <Prices {...{regularPrice, salePrice}}/>}
                </div>
			</Link>
		</div>
	)
}


export default ProductItem