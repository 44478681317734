import React from "react"
import './loader.scss'

const icon = (<svg className="spinner" width="30px" height="30px" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<rect className="spinner__rect" x="0" y="0" width="100" height="100" fill="none"></rect>
<circle className="spinner__circle" cx="50" cy="50" r="40" stroke="#999999" fill="none" strokeWidth="6" strokeLinecap="round">
</circle>
</svg>)

class Loader extends React.Component {

  state = {forceOff:false}
  timer = null

  componentDidMount(){
    if(typeof this.props.timeout === 'number'){
      this.timer = setTimeout(()=>{
        this.setState({
          forceOff:true
        })
        clearTimeout(this.timer)
      }, this.props.timeout)
    }
  }

  render() {
    if(this.props.show === false || this.state.forceOff === true){
        return null
    }
    
    return (
      <div className="app-modal app-loader">
        <div className="app-modal-bg"></div>
        <div className="app-modal-inner">
            <h3>
              {this.props.label || icon}
            </h3>
        </div>
      </div>
    )
  }
}

export default Loader
