import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { ResizedImage, Modal } from '../'
import { cartActions } from '@stores'
import {truncate} from 'lodash'

import './cart.scss'

const CartItem = ({
	image,
	quantity,
	name,
	value,
	product_id,
	changeProductQty,
	unit,
	removeFromCart,
}) => {
	return (
		<div className='cart-item col-12 col-md-4'>
			<div className="cart-item-image">
				<ResizedImage {...image} size={'350w'} />
					<a
						className="cart-item-delete"
						onClick={() => {
							removeFromCart({ product_id })
						}}
					>
						<i className="icofont-close"></i>
					</a>
				</div>
				<div className="cart-item-description">
					<h6>{truncate(name, {length: 30})}</h6>
					<h6>{value}{' '}<small>{unit}</small></h6>
					<h6><button
					onClick={() => {
						changeProductQty({ product_id, quantity: quantity - 1 })
					}}
				>
				<i className="icofont-minus"></i>

				</button>{quantity}<button
				onClick={() => {
					changeProductQty({ product_id, quantity: quantity + 1 })
				}}
			>
				<i className="icofont-plus"></i>
			</button></h6>
				</div>
			{/*
			<button
				onClick={() => {
					changeProductQty({ product_id, quantity: quantity - 1 })
				}}
			>
				-
			</button>
			{quantity}
			<button
				onClick={() => {
					changeProductQty({ product_id, quantity: quantity + 1 })
				}}
			>
				+
			</button>{' '}
			x {name} = {value}{' '}
			*/}
		</div>
	)
}

class Cart extends React.Component {
	modal = React.createRef()

	render() {
		const {
			store,
			resetCart,
			changeProductQty,
			removeFromCart,
		} = this.props
		const { items, sum, unit } = store

		if (items.length === 0) {
			return (
				<div className='cart'>
					<div className='cart-toggler'>
						<Link
						activeClassName="active"
						className="app-menu-btn"
							to='/categories/'
						>
							<i className='icofont-shopping-cart' />
						</Link>
					</div>
				</div>
			)
		}

		return (
			<div className='cart'>
				<div className='cart-toggler'>
					<button
						className="app-menu-btn"
						onClick={() => {
							this.modal.current.toggle()
						}}
					>
						<i className='icofont-shopping-cart' /> {items.length} 
					</button>
				</div>
				<Modal title={''} ref={this.modal}>
					<div className='cart-header'>
						<h3>Koszyk</h3>
						<button
						
							onClick={() => {
								resetCart()
							}}
						>
							Wyczyść
						</button>
					</div>
					<div className='cart-modal'>
						<div className='cart-items row'>
							{items.map((el,k) => (
								<CartItem
									{...el}
									key={k}
									changeProductQty={changeProductQty}
									removeFromCart={removeFromCart}
								/>
							))}
						</div>
					</div>
					{/* desktop */}
					<div className='d-none d-md-block'>
						<div className='cart-footer'>
						<h2>Razem: <span>{sum.val} <small>{unit}</small></span></h2>
						<Link
							to='/checkout/'
						>
							Do płatności
						</Link>
					</div>
					</div>
					{/* mobile */}
					<div className='d-md-none'>
						<div className="cart-footer">
							<div className="row">
								<h2>Razem: <span>{sum.val} <small>{unit}</small></span></h2>
							</div>
							<div className="row">
							<Link
							to='/checkout/'
						>
							Do płatności
						</Link>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { store: state.Cart }
}

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Cart)
