import React from 'react'
import { ResizedImage as Image } from '@common'

const GalleryItem = (props) => {
    const { onClick, className=[] } = props
	return (
        <div className={['gallery-item', ...className].join(' ')}>
            <a className="gallery-item-image" onClick={onClick}>
                <Image {...props} size={'300w'}/>
            </a>
		</div>
	)
}
export default GalleryItem
