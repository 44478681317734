import React from 'react'
import { Header, Footer, Loader, Parallax, Observer, Head, Modal } from '../'
import './layout.scss'

// const Layout = ({
// 	children,
// 	animation = true,
// 	imBusy = false,
// 	title = false,
// 	subtitle = false,
// 	parallaxImg = {},
// 	siteMetaData, seo
// }) => {
// 	modal = React.createRef()
// 	return (
// 		<React.Fragment>
// 			<Head seo={seo} siteMetaData={siteMetaData}/>
// 			<div className='app container-fluid'>
// 				<Loader show={imBusy} />
// 				<Header />
// 				{title && (
// 					<Parallax image={parallaxImg} height={350}>
// 						<div className='container'>
// 							<h1>{title}</h1>
// 							{subtitle && <h4>{subtitle}</h4>}
// 						</div>
// 					</Parallax>
// 				)}
// 				<main
// 					className={[
// 						'app-page',
// 						animation ? '' : '',
// 					].join(' ')}
// 				>
// 					{children}
// 				</main>
// 				<Footer />
// 			</div>
// 		</React.Fragment>
// 	)
// }

class Layout extends React.Component {
	modal = React.createRef()

	componentDidMount(){
		if(localStorage.getItem('over18') !== 'true'){
			this.modal.current.toggle()
		}
	}

	render() {
		const {
			children,
			animation = true,
			imBusy = false,
			title = false,
			subtitle = false,
			parallaxImg = {},
			siteMetaData, seo,
		} = this.props

		
		return (
			<React.Fragment>
			<Head seo={seo} siteMetaData={siteMetaData}/>
			<div className='app container-fluid'>

				<Loader show={imBusy} />
				<Header />
				<Modal title={''} ref={this.modal} withClose={false}>
					<div className="row justify-content-around">
						<h2 className="text-center">Czy masz ukończone 18 lat?</h2>
					</div>
					<div className="row justify-content-around">
						<a className="app-button" onClick={()=>{
							localStorage.setItem('over18', 'true');
							this.modal.current.toggle();
					}}>TAK</a>
						<a className="app-button alt" href="https://www.facebook.com/gronosklepibar" onClick={()=>localStorage.setItem('over18', 'false')}>NIE</a>
					</div>
				</Modal>
				
				{title && (
					<Parallax image={parallaxImg} height={350}>
						<div className='container'>
							<h1>{title}</h1>
							{subtitle && <h4>{subtitle}</h4>}
						</div>
					</Parallax>
				)}
				<main
					className={[
						'app-page',
						animation ? '' : '',
					].join(' ')}
				>
					{children}
				</main>
				<Footer />
			</div>
		</React.Fragment>
		)
	}
}

export default Layout
