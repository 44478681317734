import React from "react"
import { WPApi } from "@api"
import { TextInput } from ".."
import { StaticQuery, graphql } from "gatsby"

import is from 'is_js'

class NewsletterForm extends React.Component {

    state = {
        email: "",
        msg: "",
        imBusy: false,
    }

    action = () => {
        if (is.email(this.state.email) === true) {
            this.setState({
                imBusy: true,
            });
            WPApi.sendMail(this.state.email).then((data) => {
                this.setState({
                    email: "",
                    msg: 'Dziękujemy za zapisanie się do newslettera!',
                    imBusy: false,
                });
            });
        } else {
            this.setState({
                msg: 'Wprowadzony adres e-mail jest nieprawidłowy'
            })
        }
    }
    render() {
        if (this.state.imBusy) {
            return (
                <div className="newsletter">
                    <h6 className="message">Dodawanie do newslettera</h6>
                </div>
            )
        }
        const { title = "Zapisz sie do newslettera",
            subtitle = "Podeślemy Ci nową ciekawą wiedzę" } = this.props
        return (
            <React.Fragment>
                <div className="container">
                    <form className="row align-items-center">
                        <div className="col-12 col-md-6 ">
                            <h3 className="title">{title}</h3>
                            <h6 className="subtitle">{subtitle}</h6>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="newsletter-form">
                                <TextInput
                                    type="email"
                                    value={this.state.email}
                                    placeholder="Wprowadź adres email"
                                    onChange={ev => {
                                        const { value } = ev.target
                                        this.setState({
                                            email: value,
                                        });
                                    }}
                                />
                                <button className="button" onClick={this.action}>Zapisz się</button>
                            </div>
                            <div className="messages">
                                {is.email(this.state.email) !== true && this.state.email.length > 2 ? (
                                    <small className="error-message">Nieprawidłowy adres e-mail</small>
                                ) : (
                                        <small className="hidden-message">error-message</small>
                                    )}
                            </div>
                        </div>
                        <div className="col-12">
                            <small><a href="/polityka-prywatnosci">* Zapisując się do newslettera zgadzasz się na przetwarzanie swoich informacji osobowych zgodnie z naszą Polityką Prywatności.</a></small>
                            <div className="messages">
                                {this.state.msg != '' ? (
                                    <h6 className="confirm">{this.state.msg}</h6>
                                ) : (
                                        <h6 className="confirm__hidden">message placeholder</h6>
                                    )}
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default NewsletterForm
