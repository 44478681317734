import React from 'react'
import { Link } from 'gatsby'
import {truncate} from 'lodash'
import './breadcrumbs.scss'

const Span = ({html, className})=>{
	return <span dangerouslySetInnerHTML={{__html:html}} className={className}></span>
}

const Breadcrumbs = ({ elements = [] }) => {
	const first = { label: 'Główna', url: '/' }
	const [second, third, fourth] = elements
	
	return (
		<div className='breadcrumbs'>
			<Link activeClassName="active" to={first.url}>{first.label}</Link>
			{second && (
				<React.Fragment>
				<span>{' / '}</span>
					<Link activeClassName="active" to={second.url}>
					<Span html={truncate(second.label, {length:60})}/>
					</Link>
				</React.Fragment>
			)}
			{third && (
				<React.Fragment>
					<span>{' / '}</span>
					<Link activeClassName="active" to={third.url}>
					<Span html={truncate(third.label, {length:60})}/>
					</Link>
				</React.Fragment>
			)}
			{fourth && (
				<React.Fragment>
				<span>{' / '}</span>
					<Link activeClassName="active" to={fourth.url}>
						<Span className="last" html={truncate(fourth.label, {length:15})}/>
					</Link>
				</React.Fragment>
			)}
		</div>
	)
}

export default Breadcrumbs
