import React from 'react'
import { truncate } from 'lodash'
import { Link, useStaticQuery } from 'gatsby'
import { ResizedImage as Image } from '@common'

const Placeholder = () => {
    const { WP } = useStaticQuery(graphql`
        query{
            WP {
                mediaItemBy(slug: "pro-icon") {
                  sourceUrl
                  srcSet
                  sizes
                }
              }
        }
    `)

    return <Image {...WP.mediaItemBy} size={'285w'} />
}

const CategoryItem = (props) => {
    const { image, slug, name, count, onClick, description, className=[] } = props
	return (
		<div className={['category-item', ...className].join(' ')}>
			<Link to={`/categories/${slug}/`}
			onClick={() => {
                // onClick({slug, name})
			  }}>
				<div className="category-item-image">
                    {image===null ? (
                        <Placeholder />
                    ) : (
                        <Image {...image} size={'600w'}/>
                    )}
                    <div className="category-item-inner">
						<button className="category-item-button">Zobacz produkty</button>
                    </div>
                </div>

                <div className="category-item-footer">
                    <div className="category-item-title">
                        <h4 dangerouslySetInnerHTML={{__html:name}}/>
                        <h5 dangerouslySetInnerHTML={{__html:description}}/>
                    </div> 
                </div>
			</Link>
		</div>
	)
}
export default CategoryItem
